import { createContext, useEffect, useState, useContext, useRef } from "react";
import "./App.css";
import { gql, useApolloClient } from "@apollo/client";
import { GET_ROWS } from "./graphql-ops";
import { useAuth0 } from "@auth0/auth0-react";
import { RefetchContext } from "./refetchProvider";
import { GlobalContext } from "./globalContext";
import { GetHeaderText } from "./DatagridFunctions";

export const updateRowTags = (
  rowContext: RowContextType | null,
  listId: string,
  rowObject: any,
  columns: any[]
) => {
  if (!rowContext) return;

  if (!rowContext.tagsMap.has(listId)) {
    rowContext.tagsMap.set(listId, new Map<string, string[]>());
  }
  const viewTags = rowContext.tagsMap.get(listId)!;

  columns.forEach((column) => {
    if (column.type === "type") {
      const tags = rowObject[column.field];
      if (tags) {
        if (!viewTags.has(column.field)) {
          viewTags.set(column.field, []);
        }
        const fieldTags = viewTags.get(column.field)!;

        const addTag = (tag: string) => {
          if (!fieldTags.includes(tag?.trim()) && tag !== "" && tag !== " ") {
            fieldTags.push(tag?.trim());
          }
        };

        if (Array.isArray(tags)) {
          tags.forEach((item: string | string[]) => {
            if (Array.isArray(item)) {
              item.forEach(addTag);
            } else if (typeof item === "string") {
              addTag(item?.trim());
            }
          });
        } else if (typeof tags === "string") {
          addTag(tags?.trim());
        }

        viewTags.set(column.field, fieldTags);
      }
    }
  });

  rowContext.tagsMap.set(listId, viewTags);
};

interface RowContextType {
  rows: Map<string, any>;
  setAllRows: React.Dispatch<React.SetStateAction<Map<string, any>>>;
  tagsMap: Map<string, Map<string, string[]>>;
  setTagsMap: React.Dispatch<
    React.SetStateAction<Map<string, Map<string, string[]>>>
  >;
  loadingProgress: Map<string, { current: number; total: number }>;
  isLoading: boolean;
}

export const RowContext = createContext<RowContextType | null>(null);

interface RowProviderProps {
  children: React.ReactNode;
}

export enum MessageStatus {
  SUCCESS = "success",
  ERROR = "error",
}

const doViewsMatch = (views1: any, views2: any) => {
  const ids1 = views1?.map((view: any) => view._id).sort();
  const ids2 = views2?.map((view: any) => view._id).sort();
  return JSON.stringify(ids1) === JSON.stringify(ids2);
};

const PAGE_SIZE = 500; // Increased page size since we're loading everything anyway

const RowProvider: React.FC<RowProviderProps> = ({ children }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const client = useApolloClient();
  const context = useContext(RefetchContext);
  const globalContext = useContext(GlobalContext);

  const [views, setViews] = useState<any>(undefined);
  const [allRows, setAllRows] = useState<any>({});
  // const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [tagsMap, setTagsMap] = useState<any>(new Map());
  const [loadingProgress, setLoadingProgress] = useState<
    Map<string, { current: number; total: number }>
  >(new Map());

  const currentViewsRef = useRef<string[]>([]);
  const abortControllersRef = useRef<Map<string, AbortController>>(new Map());

  useEffect(() => {
    if (context?.allViews && !doViewsMatch(views, context?.allViews)) {
      setViews(context?.allViews);
      currentViewsRef.current = context?.allViews.map((view: any) => view._id);

      // Reset progress for new views
      const newProgress = new Map();
      context?.allViews.forEach((view: any) => {
        newProgress.set(view._id, { current: 0, total: 0 });
      });
      setLoadingProgress(newProgress);
    }
  }, [context?.allViews, views]);

  const fetchRowsForView = async (
    viewId: string,
    page: number,
    signal?: AbortSignal
  ) => {
    const accessToken = await getAccessTokenSilently();

    const result = await fetch(`https://crm.mercero-api.com/graphql`, {
      //https://crm.mercero-api.com/graphql
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      signal,
      body: JSON.stringify({
        query: `query GetRowsByViewId($input: ObjectId!, $page: Int!, $pageSize: Int!) {
          GetRowsByViewId(input: $input, page: $page, pageSize: $pageSize) {
            rows {
              _id
              createdAt
              rowId
              rowObject
              updatedAt
              viewId {
                _id
              }
              relatedRows {
                tags
                rows {
                  _id
                  rowObject
                  viewId {
                    name
                    _id
                  }
                }
              }
              latestActivity {
                commentId {
                  comment
                  userId {
                    name
                  }
                }
                timestamp
              }
              isDeleted
            }
            pageInfo {
              currentPage
              pageSize
              totalPages
              totalCount
              hasNextPage
              hasPreviousPage
            }
          }
        }`,
        variables: {
          input: viewId,
          page,
          pageSize: PAGE_SIZE,
        },
      }),
    });

    return await result.json();
  };

  const loadAllDataForView = async (viewId: string) => {
    try {
      // Create new abort controller for this view
      const controller = new AbortController();
      abortControllersRef.current.set(viewId, controller);

      let currentPage = 1;
      let hasMore = true;

      // Get first page to determine total count
      const firstResponse = await fetchRowsForView(
        viewId,
        currentPage,
        controller.signal
      );
      const pageInfo = firstResponse?.data?.GetRowsByViewId?.pageInfo;
      const totalCount = pageInfo?.totalCount || 0;

      // Update progress
      setLoadingProgress((prev) => {
        const newProgress = new Map(prev);
        newProgress.set(viewId, {
          current: Math.min(PAGE_SIZE, totalCount),
          total: totalCount,
        });
        return newProgress;
      });

      // Process first page
      const initialRows = processRows(
        firstResponse?.data?.GetRowsByViewId?.rows,
        views
      );
      setAllRows((prev: Record<string, Map<string, any>>) => ({
        ...prev,
        [viewId]: initialRows,
      }));

      hasMore = pageInfo?.hasNextPage;
      currentPage++;

      // Load remaining pages
      while (hasMore) {
        const response = await fetchRowsForView(
          viewId,
          currentPage,
          controller.signal
        );
        const newRows = processRows(
          response?.data?.GetRowsByViewId?.rows,
          views
        );

        setAllRows((prev: Record<string, Map<string, any>>) => {
          const updatedRows = new Map(prev[viewId]);
          newRows.forEach((value: any, key: string) =>
            updatedRows.set(key, value)
          );
          return { ...prev, [viewId]: updatedRows };
        });

        // Update progress
        setLoadingProgress((prev) => {
          const newProgress = new Map(prev);
          const current = Math.min(currentPage * PAGE_SIZE, totalCount);
          newProgress.set(viewId, { current, total: totalCount });
          return newProgress;
        });

        hasMore = response?.data?.GetRowsByViewId?.pageInfo?.hasNextPage;
        currentPage++;

        // Add a small delay to prevent overwhelming the server
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      abortControllersRef.current.delete(viewId);
    } catch (error: any) {
      if (error.name === "AbortError") {
        console.log("Loading cancelled for view:", viewId);
      } else {
        console.error("Error loading data for view:", viewId, error);
        setError(error);
      }
    }
  };

  useEffect(() => {
    const loadAllData = async () => {
      if (!views || views.length === 0) return;

      // setLoading(true);

      try {
        // Cancel any ongoing loads
        abortControllersRef.current.forEach((controller) => controller.abort());
        abortControllersRef.current.clear();

        // Load data for all views concurrently
        await Promise.all(
          views.map((view: { _id: string }) => loadAllDataForView(view._id))
        );
      } finally {
        // setLoading(false);
      }
    };

    loadAllData();

    // Cleanup function to abort any ongoing requests when unmounting
    return () => {
      abortControllersRef.current.forEach((controller) => controller.abort());
    };
  }, [views]);

  const processRows = (rows: any[], views: any[]) => {
    const rowView = views.find((view) => view?._id === rows?.[0]?.viewId?._id);

    return rows?.reduce((map: any, row: any) => {
      const relatedRows = row?.relatedRows?.rows?.map((r: any) => {
        const parsedRow = JSON.parse(r?.rowObject);
        const relatedView = views?.find((v: any) => v._id === r?.viewId?._id);
        return {
          _id: r?._id,
          merc_viewId: r?.viewId?._id,
          merc_viewName: r?.viewId?.name,
          merc_Header: GetHeaderText(parsedRow, relatedView?.columns || []),
          ...parsedRow,
        };
      });
      try {
        const rowObject = JSON.parse(row.rowObject);

        if (rowObject) {
          updateRowTags(
            {
              rows: map,
              setAllRows: setAllRows,
              tagsMap: tagsMap,
              setTagsMap: setTagsMap,
              loadingProgress: loadingProgress,
              isLoading: false,
            },
            row.viewId?._id,
            rowObject,
            rowView?.columns
          );
        }
      } catch (e) {
        console.error(e);
      }

      map.set(row._id, {
        ...JSON.parse(row.rowObject),
        _id: row._id,
        relatedRows: relatedRows,
        latestActivity: row.latestActivity,
        createdAt: row.createdAt,
        latestEmail: row.latestEmail?.timestamp,
      });
      return map;
    }, new Map());
  };

  useEffect(() => {
    if (allRows) {
      globalContext?.setAllRows(allRows);
    }
  }, [allRows]);

  return (
    <RowContext.Provider
      value={{
        rows: allRows,
        setAllRows,
        tagsMap,
        setTagsMap,
        loadingProgress,
        isLoading: false,
      }}
    >
      {children}
    </RowContext.Provider>
  );
};

export default RowProvider;
