import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  GridColumnMenu,
  GridColumnMenuItemProps,
  GridColumnMenuProps,
  GridToolbarQuickFilter,
  gridColumnVisibilityModelSelector,
  gridExpandedRowCountSelector,
  gridFilteredSortedRowIdsSelector,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import React, { useCallback, useContext, useEffect, useState } from "react";
import "./App.css";
import { GridApi } from "@mui/x-data-grid-premium";
import { useAuth0 } from "@auth0/auth0-react";
import { filterOutColumns } from "./layout";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_ROWS, GET_ROWS_BY_QUERY } from "./graphql-ops";
import useUpsertView from "./useUpsertView";
//import { update } from "lodash";
import CustomizedMenus from "./topButton";
import ShareView from "./shareView";
import EditColumn from "./editColumn";
import { RefetchContext } from "./refetchProvider";
import _, { debounce } from "lodash";
import NewRow from "./newRow";
import EditView from "./editView";
import IndividualView, { CustomChip } from "./individualView";
import useUpdateRow from "./useUpdateRow";
import MapIcon from "@mui/icons-material/Map";
import useCreateRow from "./useCreateRow";
import ClearIcon from "@mui/icons-material/Clear";
import {
  ColorMenuItems,
  HighlightRowMenu,
  getHighlightRowClass,
} from "./highlightRow";
import UndoIcon from "@mui/icons-material/Undo";
import FilterListIcon from "@mui/icons-material/FilterList";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import { CustomButton } from "./PersonalDetails";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import useUpdateManyRows from "./useUpdateManyRows";
import { cache } from "./cache";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";

import EmailRelatedRows from "./emailRelatedRows";
import { GlobalContext } from "./globalContext";
import { cleanFilterModel } from "./DatagridFunctions";

const MemoizedDataGridPremium = React.memo(DataGridPremium);

declare module "@mui/x-data-grid-premium" {
  interface ColumnMenuPropsOverrides {
    setEditColumn: (value: React.SetStateAction<boolean>) => void;
    setColumnToEdit: (value: React.SetStateAction<any>) => void;
  }
}

export enum StatusEnum {
  New = "New",
  Done = "Done",
}

export interface Note {
  note: string;
  date: Date | undefined;
  status: StatusEnum | undefined;
}

const updateFilterView = (
  model: any,
  updateView: any,
  selectedView: any,
  initialState: any
) => {
  updateView({
    variables: {
      query: {
        _id: selectedView._id,
      },
      view: {
        initialState: JSON.stringify({
          ...initialState,
          filter: {
            ...initialState.filter,
            filterModel: {
              ...model,
              quickFilterValues: [],
            },
          },
        }),
      },
    },
  });
  // refetchView();
};

const debouncedUpdateView = _.debounce(updateFilterView, 5000);

const handleInitialStateChange = async (
  apiRef: React.MutableRefObject<GridApi>,
  selectedView: any,
  getAccessTokenSilently: () => Promise<string>,
  updateView: any,
  refetchView?: any
) => {
  if (!!!selectedView && !!!selectedView?._id) {
    return;
  }
  // const accessToken = await getAccessTokenSilently();

  let initialState = apiRef.current.exportState();
  initialState = {
    ...initialState,
    columns: {
      ...initialState?.columns,
      orderedFields: initialState?.columns?.orderedFields?.filter(
        (c: string) => c !== "new-column"
      ),
    },
  };

  const updatedCols = filterOutColumns(apiRef.current.getAllColumns())?.map(
    (col: any) => {
      return {
        field: col.field,
        headerName: col.headerName,
        width: col.width || 150,
        editable: col.editable,
        type: col.type,
        display: col.display || "",
      };
    }
  );

  try {
    const { data, loading, error } = await updateView({
      variables: {
        query: {
          _id: selectedView._id,
        },
        view: {
          initialState: JSON.stringify(initialState),
          columns: updatedCols,
          columnsJsonString: JSON.stringify(updatedCols),
        },
      },
    });

    // refetchView();
  } catch (error) {
    console.log("Error updating the view: ", error);
  }
};

function CustomUserItem(props: GridColumnMenuItemProps) {
  const { myCustomHandler, myCustomValue } = props;
  return (
    <MenuItem onClick={myCustomHandler}>
      <ListItemIcon>
        <SettingsIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{myCustomValue}</ListItemText>
    </MenuItem>
  );
}

function CustomColumnMenu(
  props: GridColumnMenuProps & {
    setEditColumn: (value: React.SetStateAction<boolean>) => void;
    setColumnToEdit: (value: React.SetStateAction<any>) => void;
  }
) {
  const { setEditColumn, setColumnToEdit } = props;

  const col = props.colDef;

  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Add new item
        ColumnMenuUserItem: CustomUserItem,
        columnMenuAggregationItem: null,
        columnMenuGroupingItem: null,
      }}
      slotProps={{
        ColumnMenuUserItem: {
          // set `displayOrder` for new item
          displayOrder: 15,
          // pass additional props
          myCustomValue: "Edit Column",
          myCustomHandler: () => {
            setEditColumn(true);
            setColumnToEdit(col);
          },
        },
      }}
    />
  );
}

export default function MainDataGrid(props: {
  rows: any[];
  initialState: any;
  columns: any;
  apiRef: any;
  selectedView: any;
  refetchView: any;
  filteredOrder: any;
  setFilteredOrder: any;
  allRowsLoading: boolean;
  refetchRows: any;
}) {
  const {
    rows,
    initialState,
    columns,
    apiRef,
    selectedView,
    refetchView,
    allRowsLoading,
    setFilteredOrder,
    refetchRows,
  } = props;

  const { getAccessTokenSilently } = useAuth0();
  const [editColumn, setEditColumn] = useState(false);
  const [columnToEdit, setColumnToEdit] = useState<any>(null);
  const [contextRowId, setContextRowId] = useState<any>(null);
  const [selected, setSelected] = useState<any>([]);
  const [editingView, setEditingView] = useState(false);
  const [addRow, setAddRow] = useState(false);
  const [isEditingRow, setIsEditingRow] = useState(false);
  const [filterModel, setFilterModel] = useState<any>(undefined);
  const [visibleRowsCount, setVisibleRows] = useState<number>(0);

  const [openEmailRelatedRows, setOpenEmailRelatedRows] =
    useState<boolean>(false);

  const isMobile = useMediaQuery("(max-width:600px)");

  const { listId } = useParams();
  const navigate = useNavigate();
  let { state } = useLocation();

  const { updateView } = useUpsertView();
  const { updateRow, data, loading, error } = useUpdateRow(listId || "");
  const { updateManyRows } = useUpdateManyRows(listId || "");
  const [getRowsByQuery, { data: rowsData }] = useLazyQuery(GET_ROWS_BY_QUERY, {
    onCompleted: (data) => {
      console.log(data);

      const deletedRows = data?.rows?.map((row: any) => {
        if (row?.isDeleted) {
          return row?._id;
        }
        return null;
      });

      const existingRows = cache.readQuery({
        query: GET_ROWS,
        variables: { input: listId },
      });

      if (
        existingRows &&
        // @ts-ignore
        existingRows.GetRowsByViewId &&
        deletedRows?.length > 0
      ) {
        // @ts-ignore
        const updatedRows = existingRows.GetRowsByViewId.filter((row: any) => {
          return !(deletedRows?.includes(row._id) && row.isDeleted);
        });

        cache.writeQuery({
          query: GET_ROWS,
          variables: { input: listId },
          data: {
            GetRowsByViewId: updatedRows,
          },
        });
      }
    },
  });

  const viewIdToPass = selectedView.rootViewId || selectedView._id;

  const { createRow } = useCreateRow(viewIdToPass);

  const [showingShareView, setShowingShareView] = useState(false);

  const context = useContext(RefetchContext);
  const globalContext = useContext(GlobalContext);

  const setIsMobileOpen = context?.setIsMobileMenuOpen;

  const [columnMenuOpen, setColumnMenuOpen] = useState("");
  const [changesHistory, setChangesHistory] = useState<any>([]);
  const [highlightRowsContext, setHighlightRowsContext] =
    useState<null | HTMLElement>(null);

  const captureChange = (change: any) => {
    setChangesHistory((prevChangesHistory: any[]) => [
      ...prevChangesHistory,
      change,
    ]);
  };

  const apiUpdateRow = useCallback(
    async (poppedData: any) => {
      updateRow({
        variables: {
          set: {
            rowId: poppedData.id,
            rowObject: JSON.stringify({
              ...poppedData.oldData,
              relatedRows: undefined,
            }),
            triggeredBy: "client",
          },
          query: {
            _id: poppedData.id,
          },
        },
      });
    },
    [updateRow]
  );

  const apiUndoDelete = useCallback(
    async (rowId: string) => {
      const resp: any = await updateRow({
        variables: {
          query: {
            _id: rowId,
          },
          set: {
            isDeleted: false,
            isDeletedAt: new Date(),
            triggeredBy: "client",
          },
        },
      });
      try {
        apiRef.current.updateRows([
          JSON.parse(resp.data.updateOneRow.rowObject),
        ]);
      } catch (e) {
        alert("Error undoing");
      }
    },
    [updateRow, apiRef]
  );

  const undoLastChange = useCallback(async () => {
    if (changesHistory.length === 0) {
      return; // No changes to undo
    }

    const lastChange = changesHistory.pop();
    setChangesHistory([...changesHistory]); // Update the state to reflect the pop

    try {
      if (lastChange.type === "edit") {
        // Revert the edit by updating the row with the old data
        await apiUpdateRow(lastChange);
        // Update the grid state if necessary
        // apiRef.current.updateRows([lastChange.oldData]);
      } else if (lastChange.type === "delete") {
        // If the row was deleted, we need to add it back to undo the delete
        await apiUndoDelete(lastChange.id);
        // Add the row back to the grid state
        // apiRef.current.updateRows([lastChange.oldData]);
      }
    } catch (error) {
      console.error("Failed to undo the change: ", error);
      // If the API call fails, we should not remove the change from the history
      // Push the change back onto the stack
      setChangesHistory([...changesHistory, lastChange]);
    }
  }, [changesHistory, setChangesHistory, apiUpdateRow, apiUndoDelete]);

  const addressColumn = columns.find((col: any) => col.type === "address");
  const latCol = columns.find((col: any) => col.type === "latitude");
  const lngCol = columns.find((col: any) => col.type === "longitude");
  let shouldShowMap = !!addressColumn && !!latCol && !!lngCol;
  let relatedViewToMap = undefined;

  const relatedColumns = columns.filter((col: any) => {
    return col?.type?.includes("related");
  });

  relatedColumns?.forEach((col: any) => {
    const viewId = col.type.split("-")?.[1];
    const correctView: any = context?.allViews?.find(
      (view: any) => view._id === viewId
    );
    const relatedViewColumns = correctView?.columns;

    if (!!!relatedViewColumns) return;

    const addressColumn = relatedViewColumns.find(
      (col: any) => col.type === "address"
    );
    const latCol = relatedViewColumns.find(
      (col: any) => col.type === "latitude"
    );
    const lngCol = relatedViewColumns.find(
      (col: any) => col.type === "longitude"
    );

    if (!!addressColumn && !!latCol && !!lngCol) {
      relatedViewToMap = correctView;
    }
  });

  const handleRefetch = () => {
    if (context) {
      context.refetchViews();
    } else {
      console.error("Refetch context is not available");
    }
  };

  /**
   * Handle Context Menu
   */

  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    if (!isEditingRow) {
      event.preventDefault();
      console.log(event.currentTarget.getAttribute("data-id"));
      // const selectedContextRow = rows.find(
      //   (row) => row.id === Number(event.currentTarget.getAttribute("data-id"))
      // );
      setContextRowId(event.currentTarget.getAttribute("data-id"));
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null
      );
    }
  };

  const handleCloseContext = () => {
    setContextMenu(null);
  };

  /**
   *
   * Handle Row manipulation
   *
   */
  // Setup row state

  const [lastRowInserted, setLastRowInserted] = useState<number | undefined>(
    undefined
  );

  const handleAddRow = useCallback(async () => {
    createRow({
      variables: {
        data: { viewId: { link: selectedView._id }, isDeleted: false },
      },
    });
  }, [createRow, selectedView?._id]);

  useEffect(() => {
    if (!!lastRowInserted) {
      const lastInserted = apiRef.current.getRow(lastRowInserted);
      if (lastInserted) {
        const filteredCols = apiRef.current
          .getAllColumns()
          .filter(
            (column: any) =>
              ![
                "date",
                "actions",
                "__check__",
                "__reorder__",
                "notes",
                "id",
                "new-column",
              ].includes(column.field)
          );
        const fieldToInsert = filteredCols[0].field;
        try {
          apiRef.current.startCellEditMode({
            id: lastRowInserted,
            field: fieldToInsert,
          });
        } catch (e) {
          console.log(e);
        }
      }
    }
  }, [apiRef, lastRowInserted]);

  useEffect(() => {
    if (!!apiRef?.current?.instanceId) {
      setFilteredOrder(
        JSON.stringify(gridFilteredSortedRowIdsSelector(apiRef))
      );
    }
  }, [filterModel]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.shiftKey && event.key === "Enter") {
        handleAddRow();
      }

      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "z" || event.key === "Z")
      ) {
        undoLastChange();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleAddRow, undoLastChange]);

  const processRowUpdate = useCallback(
    async (newRow: any, oldRow: any) => {
      if (!_.isEqual(newRow, oldRow)) {
        await updateRow({
          variables: {
            set: {
              rowId: newRow._id,
              rowObject: JSON.stringify({ ...newRow, relatedRows: undefined }),
              triggeredBy: "client",
            },
            query: {
              _id: newRow._id,
            },
          },
        });

        captureChange({
          type: "edit",
          id: oldRow._id,
          oldData: oldRow,
          newData: newRow,
        });
      }

      return newRow;
    },
    [updateRow]
  );

  useEffect(() => {
    if (!!initialState?.filter?.filterModel) {
      setFilterModel(initialState?.filter?.filterModel);
    }
  }, [initialState?.filter?.filterModel]);

  useEffect(() => {
    apiRef.current.subscribeEvent("stateChange", () => {
      const count = gridExpandedRowCountSelector(apiRef.current.state);
      setVisibleRows(count);
    });
  }, [apiRef]);

  const [searchValue, setSearchValue] = useState("");

  const updateSearchValue = React.useMemo(() => {
    return debounce((newValue: any) => {
      apiRef.current.setQuickFilterValues(
        newValue.split(" ").filter((word: any) => word !== "")
      );
    }, 500);
  }, [apiRef]);

  function handleSearchValueChange(event: any) {
    const newValue = event.target.value;
    setSearchValue(newValue);
    updateSearchValue(newValue);
  }

  const emailColumn =
    !!columns && columns.find((c: any) => c?.type === "email");

  const passedData = {
    columns: columns.filter((col: any) => !!col.field),
    rows,
    initialState: {
      columns: initialState?.columns,
      filter: initialState?.filter,
      sorting: initialState?.sorting,
      pinnedColumns: initialState?.pinnedColumns,
    },
  };

  useEffect(() => {
    const restoreScrollPosition = () => {
      try {
        if (apiRef?.current) {
          const position = JSON.parse(
            localStorage.getItem(`dataGridScrollPosition-${listId}`) || "{}"
          );

          if (
            position?.top !== undefined &&
            typeof apiRef.current.scroll === "function"
          ) {
            apiRef.current.scroll(position);
            localStorage.setItem(`dataGridScrollPosition-${listId}`, "{}");
          }
        }
      } catch (e) {
        console.error("Error restoring scroll position:", e);
      }
    };

    // Delay the execution to ensure apiRef is properly initialized
    const timer = setTimeout(() => {
      if (apiRef?.current && rows?.length > 0) {
        restoreScrollPosition();
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [apiRef, rows, listId]);

  return (
    <>
      <div
        key={viewIdToPass}
        style={{
          height: "calc(100vh)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ShareView
          showingShareView={showingShareView}
          setShowingShareView={setShowingShareView}
          selectedView={selectedView}
        />
        <EditColumn
          editingColumn={editColumn}
          setEditingColumn={setEditColumn}
          column={columnToEdit}
          columns={columns}
          refetchView={refetchView}
          selectedView={selectedView}
        />
        {/* <div className="pageHeader" style={{ height: "56px" }}> */}
        <Box sx={{ display: "flex", alignItems: "center", height: "auto" }}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid
              item
              sx={{
                padding: "10px 14px",
                display: "flex",
                justifyContent: "start",
              }}
              xs={12}
              md={4}
            >
              <Grid container justifyContent={"start"} alignItems={"center"}>
                {!!isMobile && (
                  <Grid item>
                    <IconButton
                      onClick={() => !!setIsMobileOpen && setIsMobileOpen(true)}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                )}
                <Grid item>
                  <CustomizedMenus
                    apiRef={apiRef}
                    title={selectedView.name}
                    selectedView={selectedView}
                    setShowingShareView={setShowingShareView}
                    setEditingView={setEditingView}
                    columns={columns}
                    initialState={initialState}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      fontSize: "14px",
                      marginRight: "25px",
                      color: "rgba(0,0,0,.6)",
                    }}
                  >
                    {`${visibleRowsCount} records`}
                    {!!allRowsLoading && " (loading)"}
                  </Typography>
                </Grid>
                <Grid item>
                  {changesHistory.length > 0 && (
                    <IconButton
                      aria-label="undo"
                      onClick={undoLastChange}
                      sx={{
                        border: "1px solid rgb(223, 225, 228)",
                        borderRadius: "4px",
                        marginRight: "10px",
                        padding: "3px 5px",
                      }}
                    >
                      <UndoIcon sx={{ width: "20px" }} />
                    </IconButton>
                  )}
                </Grid>
                <Grid item>
                  {filterModel?.items?.length > 0 ? (
                    <Button
                      onClick={() => {
                        apiRef.current.setFilterModel({ items: [] });
                        setFilterModel({ items: [] });
                      }}
                      sx={{
                        textTransform: "none",
                        fontSize: "12px",
                        color: "#525866",
                        fontWeight: "500",
                        outline: "1px solid #525866",
                        padding: "4px 10px",
                      }}
                      startIcon={<ClearIcon sx={{ color: "#525866" }} />}
                    >
                      Clear Filters
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        apiRef?.current?.showFilterPanel();
                      }}
                      sx={{
                        textTransform: "none",
                        fontSize: "12px",
                        color: "#525866",
                        fontWeight: "500",
                        outline: "1px solid #525866",
                        padding: "4px 10px",
                      }}
                      startIcon={<FilterListIcon sx={{ color: "#525866" }} />}
                    >
                      Filter
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {!!state?.filteredRows && (
                    <Button
                      onClick={() => {
                        navigate(`.`, {
                          state: { filteredRows: undefined },
                        });
                      }}
                      style={{
                        border: "1px solid rgb(223, 225, 228)",
                        height: "28px",
                        fontSize: "13px",
                        color: "rgb(60, 65, 73)",
                        backgroundColor: "rgb(255, 255, 255)",
                        boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
                        textTransform: "none",
                        fontWeight: 500,
                        marginLeft: "16px",
                      }}
                    >
                      Clear Map Filter
                      <ClearIcon sx={{ mr: 1, width: "20px" }} />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid rgb(40 42 47)",
                  borderRadius: "4px",
                  padding: "4px",
                }}
              >
                <div style={{ padding: "4px 8px", display: "flex" }}>
                  <SearchIcon sx={{ fontSize: "18px", color: "#2b70ef" }} />
                </div>
                <InputBase
                  sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                  placeholder={`Search ${selectedView.name}`}
                  inputProps={{ "aria-label": `Search ${selectedView.name}` }}
                  onChange={handleSearchValueChange}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              justifyContent={"end"}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {(shouldShowMap || relatedViewToMap) && (
                <Link
                  to={`/map/${selectedView._id}/${
                    selectedView.rootViewId || ""
                  }`}
                  state={{
                    filteredRows: props.filteredOrder,
                    columns: JSON.stringify(columns),
                    selectedView: JSON.stringify(selectedView),
                    initialState: JSON.stringify(initialState),
                    relatedViewToMap: JSON.stringify(relatedViewToMap),
                  }}
                >
                  <Button
                    style={{
                      border: "1px solid rgb(223, 225, 228)",
                      height: "28px",
                      fontSize: "13px",
                      color: "rgb(60, 65, 73)",
                      backgroundColor: "rgb(255, 255, 255)",
                      boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
                      textTransform: "none",
                      fontWeight: 500,
                      marginRight: "30px",
                    }}
                  >
                    <MapIcon sx={{ mr: 1, width: "20px" }} />
                    View Map
                  </Button>
                </Link>
              )}
            </Grid>

            {filterModel?.items?.length > 0 && (
              <Grid
                item
                xs={12}
                sx={{
                  padding: "10px 14px",
                  borderTop: "1px solid #ededed",
                  marginTop: "4px",
                }}
              >
                <Grid container justifyContent={"space-between"}>
                  <Grid item>
                    {filterModel?.items?.map((filterItem: any) => {
                      return (
                        <CustomChip
                          key={filterItem?._id}
                          label={
                            <>
                              <span
                                style={{ fontWeight: "500" }}
                              >{`${filterItem?.field}`}</span>
                              <span> {`${filterItem?.operator}`} </span>
                              <span style={{ fontWeight: "500" }}>
                                {getFilterValue(filterItem?.value)}
                              </span>
                            </>
                          }
                          onClick={() => {
                            apiRef?.current?.showFilterPanel();
                          }}
                          onDelete={() => {
                            const newItems = filterModel?.items?.filter(
                              (item: any) => item.id !== filterItem?.id
                            );
                            apiRef.current.setFilterModel({ items: newItems });
                            setFilterModel({ items: newItems });
                          }}
                          deleteIcon={
                            <ClearIcon
                              sx={{
                                color: "#525866 !important",
                                height: "18px !important",
                                marginLeft: "5px !important",
                              }}
                            />
                          }
                          sx={{ marginRight: "12px" }}
                        />
                      );
                    })}
                    <IconButton
                      onClick={() => {
                        apiRef?.current?.showFilterPanel();
                      }}
                      sx={{
                        padding: "2px",
                        borderRadius: "3px",
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Link
                      to={`/list/new/${
                        selectedView.rootViewId || selectedView._id
                      }`}
                      state={{ rootView: selectedView }}
                    >
                      <Button
                        sx={{
                          textTransform: "none",
                          fontSize: "12px",
                          color: "#525866",
                          fontWeight: "500",
                          outline: "1px solid #525866",
                          padding: "4px 10px",
                        }}
                        startIcon={<LibraryAddIcon />}
                      >
                        Save List With Filters
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
        {/* </div> */}
        <MemoizedDataGridPremium
          cellSelection
          onClipboardPasteEnd={(params) => {
            console.log(params);
          }}
          onClipboardPasteStart={(params) => {
            console.log(params);
          }}
          filterModel={cleanFilterModel(filterModel, columns)}
          hideFooter
          loading={!(columns && rows && !!apiRef)}
          onCellEditStart={() => {
            setIsEditingRow(true);
          }}
          onCellEditStop={() => {
            setIsEditingRow(false);
          }}
          //rowReordering
          key={selectedView._id}
          // pinnedRows={pinnedRow}
          onRowOrderChange={(newOrder) => {
            console.log(newOrder);
          }}
          getRowClassName={(params) => {
            if (!!params?.row?.merc_Color) {
              // Here you can set your condition
              return getHighlightRowClass(params?.row?.merc_Color); // Or return classes.myCustomRowColor if using makeStyles
            }
            return "";
          }}
          style={{ fontSize: "11px" }}
          rowHeight={32}
          sx={{
            ".MuiDataGrid-columnHeader": {
              height: "40px !important",
            },
            "& .MuiDataGrid-pinnedColumnHeaders": {
              backgroundColor: "#f5f6f8",
              boxShadow: "2px 0px 4px -2px rgba(0, 0, 0, 0.21)",
              height: "56px",
              paddingTop: "3px",
            },
            ".MuiDataGrid-columnHeaders": {
              height: "42px !important",
              minHeight: "42px !important",
            },
            "& .MuiDataGrid-cell": {
              borderRight: `1px solid #f1f1f1`,
              borderBottom: `1px solid #f1f1f1`,
            },
            "& .actions-cell": {
              borderRight: "none",
            },
            "& .MuiDataGrid-cellCheckbox": {
              borderRight: "none",
            },
          }}
          {...passedData}
          onRowSelectionModelChange={(newSelection: any) => {
            setSelected([...apiRef.current.getSelectedRows().values()]);
          }}
          disableRowSelectionOnClick
          getRowId={(row) => row?._id || row?.id || row?.rowId}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => {
            console.log("error");
            console.log(error);
          }}
          checkboxSelection={true}
          apiRef={apiRef}
          slots={{ columnMenu: CustomColumnMenu }}
          slotProps={{
            columnMenu: {
              setEditColumn: setEditColumn,
              setColumnToEdit: setColumnToEdit,
            },
            row: {
              onContextMenu: handleContextMenu,
            },
            cell: {
              tabIndex: 0,
            },
            filterPanel: {
              filterFormProps: {
                filterColumns: ({ columns }) => {
                  const columnVisibilityModel =
                    gridColumnVisibilityModelSelector(apiRef);
                  return columns
                    .filter((col) => {
                      if (col.field === "merc_Color") {
                        return true;
                      }

                      if (columnVisibilityModel[col.field] === false) {
                        return false;
                      }

                      if (col.field === "new-column") {
                        return false;
                      }

                      return true;
                    })
                    .map((col) => col.field);
                },
              },
            },
          }}
          onSortModelChange={(model, details) => {
            if (!_.isEqual(model, initialState?.sorting?.sortModel)) {
              handleInitialStateChange(
                apiRef,
                selectedView,
                getAccessTokenSilently,
                updateView,
                refetchView
              );
            }
          }}
          onColumnWidthChange={(params) => {
            handleInitialStateChange(
              apiRef,
              selectedView,
              getAccessTokenSilently,
              updateView,
              refetchView
            );
          }}
          onColumnHeaderClick={(params, event, details) => {
            event.defaultMuiPrevented = true;
            if (params.field === columnMenuOpen) {
              apiRef.current.hideColumnMenu(params.field);
              setColumnMenuOpen("");
            } else {
              setColumnMenuOpen(params.field);
              apiRef.current.showColumnMenu(params.field);
            }
          }}
          onFilterModelChange={async (model, details) => {
            setFilterModel(model);

            if (
              // !!details.reason &&
              details.reason !== "restoreState" &&
              // (!!details.reason || model?.items?.length > 0) &&
              !_.isEqual(model, filterModel)
            ) {
              debouncedUpdateView(
                model,
                updateView,
                selectedView,
                initialState
              );
              // setFilterModel(model);
            }
          }}
          onColumnOrderChange={(params, event, details) => {
            handleInitialStateChange(
              apiRef,
              selectedView,
              getAccessTokenSilently,
              updateView,
              refetchView
            );
          }}
          onColumnVisibilityModelChange={(model, details) => {
            if (
              !_.isEqual(model, initialState?.columns?.columnVisibilityModel)
            ) {
              handleInitialStateChange(
                apiRef,
                selectedView,
                getAccessTokenSilently,
                updateView,
                refetchView
              );
            }
          }}
          onPinnedColumnsChange={(params, details) => {
            if (!_.isEqual(params, initialState?.pinnedColumns)) {
              handleInitialStateChange(
                apiRef,
                selectedView,
                getAccessTokenSilently,
                updateView,
                refetchView
              );
            }
          }}
        />
        <Box
          sx={{
            backgroundColor: "white",
            padding: "10px 14px",
          }}
        >
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item flexDirection={"row"} display={"flex"} gap={"12px"}>
              <CustomButton
                onClick={(e) => {
                  e.preventDefault();
                  setAddRow(true);
                }}
                sx={{
                  fontSize: "13px",
                  height: "32px",
                  padding: "0px 12px",
                  whiteSpace: "nowrap",

                  "& .MuiButton-startIcon": {
                    marginRight: "0px",
                  },
                }}
                startIcon={<AddIcon />}
              >
                Add Row
              </CustomButton>
              {selected?.length > 0 && (
                <>
                  {!!emailColumn && (
                    <Link
                      to="/email"
                      state={{
                        selectedRows: JSON.stringify(selected),
                        columns: JSON.stringify(columns),
                        emailColumn: JSON.stringify(emailColumn?.field),
                      }}
                    >
                      <CustomButton
                        sx={{
                          fontSize: "13px",
                          height: "32px",
                          padding: "0px 12px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M1.66699 5.83301L8.47109 10.5959C9.02207 10.9816 9.29756 11.1744 9.59721 11.2491C9.8619 11.3151 10.1387 11.3151 10.4034 11.2491C10.7031 11.1744 10.9786 10.9816 11.5296 10.5959L18.3337 5.83301M5.66699 16.6663H14.3337C15.7338 16.6663 16.4339 16.6663 16.9686 16.3939C17.439 16.1542 17.8215 15.7717 18.0612 15.3013C18.3337 14.7665 18.3337 14.0665 18.3337 12.6663V7.33301C18.3337 5.93288 18.3337 5.23281 18.0612 4.69803C17.8215 4.22763 17.439 3.84517 16.9686 3.60549C16.4339 3.33301 15.7338 3.33301 14.3337 3.33301H5.66699C4.26686 3.33301 3.5668 3.33301 3.03202 3.60549C2.56161 3.84517 2.17916 4.22763 1.93948 4.69803C1.66699 5.23281 1.66699 5.93288 1.66699 7.33301V12.6663C1.66699 14.0665 1.66699 14.7665 1.93948 15.3013C2.17916 15.7717 2.56161 16.1542 3.03202 16.3939C3.5668 16.6663 4.26686 16.6663 5.66699 16.6663Z"
                            stroke="white"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Email {selected?.length}{" "}
                        {selected?.length > 1 ? "People" : "Person"}
                      </CustomButton>
                    </Link>
                  )}
                  {_.chain(selected)
                    .some((o) =>
                      _.chain(o)
                        .get("relatedRows", [])
                        .thru((o) => !_.isEmpty(o))
                        .value()
                    )
                    .value() && (
                    <>
                      <EmailRelatedRows
                        openEmailRelatedRows={openEmailRelatedRows}
                        setOpenEmailRelatedRows={setOpenEmailRelatedRows}
                        selected={selected}
                      />
                      <CustomButton
                        sx={{
                          fontSize: "13px",
                          height: "32px",
                          padding: "0px 12px",
                          whiteSpace: "nowrap",
                        }}
                        onClick={async () => {
                          setOpenEmailRelatedRows(true);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M1.66699 5.83301L8.47109 10.5959C9.02207 10.9816 9.29756 11.1744 9.59721 11.2491C9.8619 11.3151 10.1387 11.3151 10.4034 11.2491C10.7031 11.1744 10.9786 10.9816 11.5296 10.5959L18.3337 5.83301M5.66699 16.6663H14.3337C15.7338 16.6663 16.4339 16.6663 16.9686 16.3939C17.439 16.1542 17.8215 15.7717 18.0612 15.3013C18.3337 14.7665 18.3337 14.0665 18.3337 12.6663V7.33301C18.3337 5.93288 18.3337 5.23281 18.0612 4.69803C17.8215 4.22763 17.439 3.84517 16.9686 3.60549C16.4339 3.33301 15.7338 3.33301 14.3337 3.33301H5.66699C4.26686 3.33301 3.5668 3.33301 3.03202 3.60549C2.56161 3.84517 2.17916 4.22763 1.93948 4.69803C1.66699 5.23281 1.66699 5.93288 1.66699 7.33301V12.6663C1.66699 14.0665 1.66699 14.7665 1.93948 15.3013C2.17916 15.7717 2.56161 16.1542 3.03202 16.3939C3.5668 16.6663 4.26686 16.6663 5.66699 16.6663Z"
                            stroke="white"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Email Related
                      </CustomButton>
                    </>
                  )}
                  <CustomButton
                    sx={{
                      fontSize: "13px",
                      height: "32px",
                      padding: "0px 12px",
                      whiteSpace: "nowrap",

                      "& .MuiButton-startIcon": {
                        marginRight: "0px",
                      },
                    }}
                    startIcon={<DeleteOutlineIcon />}
                    onClick={async () => {
                      const newMap = globalContext?.rows;

                      const idsToDelete = selected?.map((row: any) => {
                        // @ts-ignore
                        newMap?.[viewIdToPass].delete(row?._id);
                        return row?._id;
                      });

                      if (newMap) {
                        globalContext?.setAllRows({ ...newMap });
                      }

                      await updateManyRows({
                        variables: {
                          query: {
                            _id_in: idsToDelete,
                          },
                          set: {
                            isDeleted: true,
                            isDeletedAt: new Date(),
                            triggeredBy: "client",
                          },
                        },
                      });

                      getRowsByQuery({
                        variables: { query: { _id_in: idsToDelete } },
                      });

                      captureChange({
                        type: "delete",
                        id: idsToDelete,
                      });
                    }}
                  >
                    Delete {selected?.length}{" "}
                    {selected?.length > 1 ? "Rows" : "Row"}
                  </CustomButton>
                  <CustomButton
                    sx={{
                      fontSize: "13px",
                      height: "32px",
                      padding: "0px 12px",
                      whiteSpace: "nowrap",

                      "& .MuiButton-startIcon": {
                        marginRight: "0px",
                      },
                    }}
                    startIcon={<HighlightAltIcon />}
                    onClick={(event) => {
                      setHighlightRowsContext(event?.currentTarget);
                    }}
                  >
                    Highlight {selected?.length}{" "}
                    {selected?.length > 1 ? "Rows" : "Row"}
                  </CustomButton>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
        <Menu
          open={highlightRowsContext !== null}
          onClose={() => setHighlightRowsContext(null)}
          anchorEl={highlightRowsContext}
        >
          <ColorMenuItems
            viewId={viewIdToPass}
            selectedColor="red"
            rowIds={selected?.map((row: any) => row?._id)}
            updateRow={updateRow}
            handleCloseContext={handleCloseContext}
          />
        </Menu>
        <EditView
          selectedView={selectedView}
          editingView={editingView}
          setEditingView={setEditingView}
          handleRefetch={handleRefetch}
          updateView={updateView}
        />
        <NewRow
          newRow={addRow}
          setNewRow={setAddRow}
          columns={columns}
          selectedView={selectedView}
          initialState={initialState}
          refetchRows={refetchRows}
        />
        <Menu
          open={contextMenu !== null}
          onClose={handleCloseContext}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <HighlightRowMenu
            viewId={viewIdToPass}
            rowId={contextRowId}
            updateRow={updateRow}
            handleCloseContext={handleCloseContext}
          />
          <MenuItem
            onClick={async () => {
              try {
                const resp: any = await updateRow({
                  variables: {
                    query: {
                      _id: contextRowId,
                    },
                    set: {
                      isDeleted: true,
                      isDeletedAt: new Date(),
                      triggeredBy: "client",
                    },
                  },
                });

                captureChange({
                  type: "delete",
                  id: contextRowId,
                });

                handleCloseContext();
              } catch (e) {
                alert(e);
              }
            }}
          >
            Delete Row
          </MenuItem>
        </Menu>
      </div>
    </>
  );
}

function getFilterValue(value: any): string {
  if (typeof value === "string") {
    return value;
  } else if (typeof value === "number") {
    return value.toLocaleString();
  } else if (value instanceof Date) {
    return value.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
    });
  } else if (Array.isArray(value)) {
    return value.map(getFilterValue).join(", ");
  } else if (value === null || value === undefined) {
    return "N/A";
  } else if (typeof value === "object") {
    return JSON.stringify(value);
  } else {
    return String(value);
  }
}
