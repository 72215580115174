import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider,
  styled,
  Tooltip,
  TextareaAutosize,
  Chip,
  Stack,
} from "@mui/material";
import React, {
  SyntheticEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { formatPhoneNumber, stripPhoneNumber } from "./DatagridFunctions";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useCreateRow from "./useCreateRow";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import mapboxgl from "mapbox-gl";
import { StyledTab, StyledTabs } from "./styledTabs";
import BusinessIcon from "@mui/icons-material/Business";
import GroupIcon from "@mui/icons-material/Group";
import StoreIcon from "@mui/icons-material/Store";
import PaidIcon from "@mui/icons-material/Paid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { modalBoxStyle } from "./rowTodos";
import { CustomButton, CustomTextField } from "./PersonalDetails";
import { useAuth0 } from "@auth0/auth0-react";
import SearchIcon from "@mui/icons-material/Search";
import StorageIcon from "@mui/icons-material/Storage";
import MapIcon from "@mui/icons-material/TravelExplore";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import FilterListIcon from "@mui/icons-material/FilterList";
import ScienceIcon from "@mui/icons-material/Science";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import loading from "./loading.gif";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";

import {
  AddContactToDatabase,
  PropertyData,
  PropertyOwnershipDetails,
} from "./propertyResearch";
import { useQuery } from "@apollo/client";
import { GET_PROPERTIES } from "./v2-gql";
import LoadingScreen from "./loadingScreen";
import ExistingPropertyMapView from "./ExistingPropertyMapView";
import WorkspaceDropdown from "./v2WorkspaceDropDown";
import { WorkspaceContext } from "./v2workspaceProvider";
import { skipTraceServerUrl } from "./consts";

const containerStyle = {
  width: "100%",
  height: "100%",
  minHeight: "calc(100vh - 56px)",
};

export const FilterButton = styled(Button)({
  display: "block",
  padding: "4px var(--spacing-xl, 8px)",

  height: "fit-content",

  borderRadius: "var(--radius-md, 8px)",
  border: "1px solid var(--blue-dark-700, #004EEB)",
  background: "var(--blue-dark-600, #155EEF)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  // Text style
  color:
    "var(--component-colors-components-buttons-primary-button-primary-fg, #FFF)",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "20px", // 150%
  textTransform: "none",

  "&:hover": {
    background: "var(--blue-dark-700, #004EEB)", // Change this to your desired hover background
    boxShadow: "0px 2px 4px 0px rgba(16, 24, 40, 0.15)", // Optional: Change this to your desired hover box-shadow
  },
});

const ButtonStyle = {
  border: "1px solid rgb(223, 225, 228)",
  height: "28px",
  fontSize: "13px",
  color: "rgb(60, 65, 73)",
  backgroundColor: "rgb(255, 255, 255)",
  boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
  textTransform: "none",
  fontWeight: 500,
  marginRight: "30px",
};

const center = {
  lat: 39.736069202539454,
  lng: -104.98232626881855,
};

const typeToCodeRange = {
  residential: [1000, 1900],
  business: [2000, 2900],
  industrial: [3000, 3900],
  institutional: [4000, 4900],
  travel: [5000, 5700],
  assembly: [6000, 6800],
  leisure: [7000, 7900],
  resources: [8000, 8900],
  vacant: [9500, 9510],
  multifamily: 1200,
  office: [2300, 2320],
  retail: [2110, 2120],
  hospitality: 1200,
};

const callAPI = async (
  addressToSearch: string,
  getAccessTokenSilently: any,
  parcelNumber?: string
) => {
  const accessToken = await getAccessTokenSilently();
  const url = !!parcelNumber
    ? `${skipTraceServerUrl}/api/1/property/trace1/${parcelNumber}?isApn=true`
    : `${skipTraceServerUrl}/api/1/property/trace1/${addressToSearch}`;

  const replyRes = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    method: "GET",
  });

  const reply = await replyRes.json();
  return reply;
};

export default function AddPropertiesMap(props: any) {
  let { state } = useLocation();

  const { getAccessTokenSilently } = useAuth0();

  const workspaceContext = useContext(WorkspaceContext);

  const {
    loading,
    error,
    data: properties,
  } = useQuery(GET_PROPERTIES, {
    variables: {
      query: { workspaceId: workspaceContext?.selectedWorkspace?._id },
    },
    fetchPolicy: "cache-and-network",
  });

  // const [selectedDetails, setSelectedDetails] = useState<any>(undefined);
  const [showAddModal, setShowAddModal] = useState<any>(null);
  const inputRef = useRef(null);
  const [filter, setFilter] = useState<string>("");
  const [showPropertyFilter, setShowPropertyFilter] = useState(false);
  const [center, setCenter] = useState<any>([-98.5795, 39.8283]);
  const [researchOwner, setResearchOwner] = useState<any>(undefined);
  const [propData, setPropData] = useState<any>(undefined);

  const [loadingPropDetails, setLoadingPropDetails] = useState(false);
  const [regridParcelDetails, setRegridParcelDetails] =
    useState<any>(undefined);
  const [contactToAdd, setContactToAdd] = useState<any>(undefined);
  const [selectedAddress, setSelectedAddress] = useState<any>(undefined);

  const { createRow } = useCreateRow("65a556845638cf6a93b63dc6");

  React.useEffect(() => {
    let autocomplete: any;

    if (inputRef.current) {
      // Initialize Google Places Autocomplete
      autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
        types: [],
      });

      // Event listener to get selected value
      autocomplete.addListener("place_changed", async () => {
        const place = autocomplete.getPlace();
        const address = place.formatted_address;
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setCenter([lng, lat]);
        // onChange(field, address);
        // Make POST request with Bearer token to your API
        if (place?.types?.includes("street_address")) {
          setSelectedAddress(address);
        }
      });
    }

    return () => {
      // Remove event listener on cleanup
      if (autocomplete) {
        google.maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, [inputRef, loading]);

  if (loading) {
    return <LoadingScreen />;
  }

  const isSelectedDatabased =
    properties?.getProperties?.filter((prop: any) => {
      return (
        prop?.parcelnumb?.toLowerCase() ===
          regridParcelDetails?.parcelnumb?.toLowerCase() && !!prop?.parcelnumb
      );
    }) || [];

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      {/* <div
        style={{
          width: "100%",
          borderBottom: "1px solid #e0e0e0",
          paddingTop: "8px",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <img
          src="/merceroBrandmark.svg"
          alt="Home Logo"
          style={{
            width: "40px",
            float: "left",
            marginLeft: "16px",
            marginTop: "2px",
          }}
        />
        <StyledTabs
          value={1}
          onChange={function (
            event: SyntheticEvent<Element, Event>,
            newValue: number
          ): void {
            console.log(event);
          }}
          sx={{ width: "385px", margin: "auto" }}
        >
          <Link to="/properties">
            <StyledTab
              icon={<SearchIcon fontSize="small" />}
              iconPosition="start"
              label="Search"
            />
          </Link>
          <StyledTab
            icon={<MapIcon fontSize="small" />}
            iconPosition="start"
            label="Map"
          />
          <Link to="/properties/database">
            <StyledTab
              icon={<StorageIcon fontSize="small" />}
              iconPosition="start"
              label="Database"
            />
          </Link>
        </StyledTabs>
        <WorkspaceDropdown />
      </div> */}

      <div style={{ height: "calc(100vh)", width: "100%" }}>
        {!!showPropertyFilter && (
          <Box
            sx={{
              backgroundColor: "rgba(255,255,255)",
              position: "absolute",
              zIndex: 100,
              height: "100%",
              padding: "24px",
              width: "100%",
              borderBottom: "1px solid rgba(0,0,0,.3)",
            }}
          >
            <Typography>Building Type</Typography>
            <FormGroup sx={{ padding: "20px" }}>
              <Grid container sx={{ width: "100%" }}>
                {Object.keys(typeToCodeRange).map((key: any) => {
                  return (
                    <Grid xs={3}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={key.charAt(0).toUpperCase() + key.slice(1)}
                        value={filter === key}
                        onChange={(e) => {
                          // @ts-ignore
                          if (e.target.checked) {
                            setFilter(key);
                          } else {
                            setFilter("");
                          }
                        }}
                      />
                    </Grid>
                  );
                })}
                {/* <FormControlLabel
                    control={<Checkbox />}
                    label="Industrial"
                    value={filter === "industrial"}
                    onChange={(e) => {
                      // @ts-ignore
                      if (e.target.checked) {
                        setFilter("industrial");
                      } else {
                        setFilter("");
                      }
                    }}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Residential"
                    onChange={(e) => {
                      // @ts-ignore
                      if (e.target.checked) {
                        setFilter("residential");
                      } else {
                        setFilter("");
                      }
                    }}
                  /> */}
              </Grid>
            </FormGroup>
            <div style={{ display: "flex", gap: "12px" }}>
              <CustomButton onClick={() => setShowPropertyFilter(false)}>
                Cancel
              </CustomButton>
              <CustomButton onClick={() => setShowPropertyFilter(false)}>
                Apply Filters
              </CustomButton>
            </div>
          </Box>
        )}
        <NewRenderMap
          setRegridParcelDetails={setRegridParcelDetails}
          // setSelectedDetails={setSelectedDetails}
          // selectedDetails={selectedDetails}
          properties={properties}
          filter={filter}
          center={center}
        />

        {isSelectedDatabased?.length > 0 && (
          <ExistingPropertyMapView
            properties={properties}
            regridParcelDetails={regridParcelDetails}
            selectedProperty={isSelectedDatabased}
          />
        )}
        {(!!selectedAddress || !!regridParcelDetails) &&
          isSelectedDatabased?.length === 0 && (
            <NewParcelDetails
              regridParcelDetails={regridParcelDetails}
              // selectedDetails={selectedDetails}
              // setSelectedDetails={setSelectedDetails}
              setResearchOwner={setResearchOwner}
              setContactToAdd={setContactToAdd}
              propData={propData}
              setPropData={setPropData}
            />
          )}
        {!!contactToAdd && (
          <ResearchOwner
            setContactToAdd={setContactToAdd}
            contactToAdd={contactToAdd}
            propData={propData}
          />
        )}
        <Paper
          // component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "400px !important",
            position: "absolute",
            top: "35px",
            left: "265px",
          }}
        >
          <div style={{ padding: "10px", display: "flex" }}>
            <LocationSearchingIcon
              sx={{ fontSize: "18px", color: "#2b70ef" }}
            />
          </div>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Address"
            inputProps={{ "aria-label": "search google maps" }}
            inputRef={inputRef}
          />

          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <Tooltip title="Filter your Search">
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="filter"
              onClick={() => setShowPropertyFilter(!showPropertyFilter)}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Paper>
      </div>
    </div>
  );
}

function ResearchOwner(props: any) {
  const { setContactToAdd, contactToAdd, propData } = props;

  return (
    <div
      style={{
        position: "absolute",
        height: "calc(100vh - 60px)",
        top: "20px",
        backgroundColor: "white",
        width: "448px",
        left: "728px",
        borderRadius: "8px",
        overflowY: "scroll",
        boxShadow: "0px 0px 10px rgba(0,0,0,.3)",
      }}
    >
      <div style={{ padding: "16px", paddingBottom: "0px" }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: "600", display: "flex", alignItems: "center" }}
          onClick={() => {
            setContactToAdd(undefined);
          }}
        >
          <ArrowBackIosIcon sx={{ color: "#2b70ef", fontSize: "18px" }} />
          Close
        </Typography>
        <Divider sx={{ marginTop: "8px" }} />
      </div>

      <div
        style={{
          padding: "24px",
          paddingTop: "12px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <AddContactToDatabase
          contactToAdd={contactToAdd}
          propData={propData}
          setContactToAdd={setContactToAdd}
        />
      </div>
    </div>
  );
}

function NewParcelDetails(props: any) {
  const {
    // selectedDetails,
    setResearchOwner,
    setContactToAdd,
    regridParcelDetails,
    propData,
    setPropData,
  } = props;

  const { getAccessTokenSilently } = useAuth0();

  const [loadingOwnership, setLoadingOwnership] = useState(false);

  const parsedLat = parseFloat(regridParcelDetails?.lat);
  const parsedLng = parseFloat(regridParcelDetails?.lon);

  const fullAddress = `${regridParcelDetails?.address} ${regridParcelDetails?.city} ${regridParcelDetails?.state2} ${regridParcelDetails?.szip}`;

  const src = `https://maps.googleapis.com/maps/api/streetview?size=${448}x${252}&location=${parsedLat},${parsedLng}&key=AIzaSyBGRT9wxwnP_vbsbTE_HHUb55W5BQH4XZE`; // &fov=${fov}&heading=${heading}&pitch=${pitch}

  useEffect(() => {
    setPropData(undefined);
  }, [regridParcelDetails, setPropData]);

  return (
    <div
      style={{
        position: "absolute",
        height: "calc(100vh - 60px)",
        top: "20px",
        backgroundColor: "white",
        width: "448px",
        left: "240px",
        borderRadius: "8px",
        overflowY: "scroll",
        boxShadow: "0px 0px 10px rgba(0,0,0,.3)",
      }}
    >
      <img
        src={src}
        alt="Street View"
        style={{ width: "100%", height: "252px" }}
      />
      <div
        style={{
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        {!!propData && (
          <>
            <PropertyData
              propData={propData}
              addressToSearch={propData?.regrid?.properties?.headline}
            />
            <PropertyOwnershipDetails
              propData={propData}
              setContactToAdd={setContactToAdd}
              isCompact={true}
            />
          </>
        )}
        {!!regridParcelDetails && !!!propData && (
          <>
            <Typography variant="body2">
              <span style={{ fontWeight: "600", textTransform: "capitalize" }}>
                {regridParcelDetails?.address?.toLowerCase()}
              </span>
            </Typography>
            <Typography variant="body2" style={{ textTransform: "capitalize" }}>
              <span style={{ fontWeight: "600", textTransform: "capitalize" }}>
                Owner
              </span>
              : {regridParcelDetails?.owner?.toLowerCase()}
            </Typography>
            {/* <Button
          sx={{
            border: "1px solid rgb(223, 225, 228)",
            height: "28px",
            fontSize: "13px",
            color: "white",
            width: "fit-content",
            backgroundColor: "#2b6fef",
            boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
            textTransform: "none",
            fontWeight: 500,
            "&:hover": {
              backgroundColor: "#2561d3",
            },
          }}
          onClick={() => {
            setResearchOwner(true);
          }}
        >
          <ScienceIcon sx={{ mr: 1, width: "20px" }} />
          Find Owner Contact
        </Button> */}
            <Divider />
            <Typography variant="body2">
              <span style={{ fontWeight: "600", textTransform: "capitalize" }}>
                Parcel
              </span>
              : {regridParcelDetails?.parcelnumb}
            </Typography>
            <Typography variant="body2">
              <span style={{ fontWeight: "600", textTransform: "capitalize" }}>
                Last Sold
              </span>
              : {regridParcelDetails?.saledate}
            </Typography>
            <Typography variant="body2">
              <span style={{ fontWeight: "600", textTransform: "capitalize" }}>
                Last Sale Price
              </span>
              : {regridParcelDetails?.saleprice}
            </Typography>
            <Divider />
            <Typography variant="body2">
              <span style={{ fontWeight: "600", textTransform: "capitalize" }}>
                Zoning
              </span>
              : {regridParcelDetails?.zoning}
            </Typography>
            <CustomButton
              startIcon={
                loadingOwnership ? (
                  <img
                    src={loading}
                    alt="loading"
                    style={{
                      width: "20px",
                      margin: "auto",
                      display: "block",
                    }}
                  />
                ) : (
                  <PersonSearchIcon />
                )
              }
              onClick={async () => {
                setLoadingOwnership(true);
                try {
                  await callAPI(
                    fullAddress,
                    getAccessTokenSilently,
                    regridParcelDetails?.parcelnumb
                  ).then((reply) => {
                    setPropData(reply);
                  });
                  setLoadingOwnership(false);
                } catch (error) {
                  console.log("Error getting ownership", error);
                  setLoadingOwnership(false);
                }
              }}
            >
              Research Ownership
            </CustomButton>
          </>
        )}
      </div>
    </div>
  );
}

function NewRenderMap(props: any) {
  const {
    // setSelectedDetails,
    filter,
    center,
    // selectedDetails,
    setRegridParcelDetails,
    properties,
  } = props;

  const { getAccessTokenSilently } = useAuth0();

  const [areLayersLoaded, setAreLayersLoaded] = useState(false);

  const token = getAccessTokenSilently();

  var url = "https://tiles.regrid.com";

  mapboxgl.accessToken =
    "pk.eyJ1IjoicmRlY2siLCJhIjoiY2xyOXBnNDMyMDU3dTJsc3JpM3ZjNG01NSJ9.SDpVAi4--gRqz6VqwpZ6YQ";
  let map = useRef<any>(null);
  //   mapbox://styles/mercerojake/cluyiz1cz005601og5jdk2z6d
  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: "newMap", // make sure this container id matches your div id
      style: "mapbox://styles/mapbox/satellite-streets-v12", // mapbox://styles/mapbox/streets-v11  mapbox://styles/mapbox/satellite-streets-v12
      zoom: 4,
      center: center,
    });
    let lastClickedId: any = null;
    let hoverId: any = null;

    map.current.showTileBoundaries = false;

    map.current.on("load", async function () {
      try {
        var parcelCreate = await fetch(
          `${url}/api/v1/parcels?format=mvt&token=eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJyZWdyaWQuY29tIiwiaWF0IjoxNzM4NjMyNzk5LCJnIjo3MTMzOSwidCI6MSwiY2FwIjoicGE6dHMiLCJ0aSI6MzM4fQ.-15dMkCpOfV5ZIsRn5CBA2ebTSWkSLWQlWmj3FxBuzk`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              dataType: "json",
            },
          }
        ).then(async (response) => {
          const json = await response.json();
          await setup(json);
          setAreLayersLoaded(true);
        });
      } catch (error) {
        console.log("Error getting parcel layer", error);
      }

      async function setup(layerData: any) {
        var data = layerData;
        // console.log("Got parcel layer", layerData);

        data.tiles = [
          "https://tiles.regrid.com/api/v1/sources/parcel/layers/7f2fc977750e32c6470079e5dac5a8e52a1ccc03/{z}/{x}/{y}.mvt?token=eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJyZWdyaWQuY29tIiwiaWF0IjoxNzM4NjMyNzk5LCJnIjo3MTMzOSwidCI6MSwiY2FwIjoicGE6dHMiLCJ0aSI6MzM4fQ.-15dMkCpOfV5ZIsRn5CBA2ebTSWkSLWQlWmj3FxBuzk",
        ];
        data.vector = [
          "https://tiles.regrid.com/api/v1/sources/parcel/layers/7f2fc977750e32c6470079e5dac5a8e52a1ccc03/{z}/{x}/{y}.mvt?token=eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJyZWdyaWQuY29tIiwiaWF0IjoxNzM4NjMyNzk5LCJnIjo3MTMzOSwidCI6MSwiY2FwIjoicGE6dHMiLCJ0aSI6MzM4fQ.-15dMkCpOfV5ZIsRn5CBA2ebTSWkSLWQlWmj3FxBuzk",
        ];
        data.id = "7f2fc977750e32c6470079e5dac5a8e52a1ccc03";

        // Register the parcel source using the tile URL we just got
        map.current.addSource(data.id, {
          type: "vector",
          tiles: data.tiles,
          // @ts-ignore
          promoteId: {
            "7f2fc977750e32c6470079e5dac5a8e52a1ccc03": "parcelnumb",
          },
        });

        // Add basic parcel outlines

        map.current.addLayer({
          id: "parcels-fill",
          type: "fill",
          source: data.id,
          "source-layer": data.id,
          minzoom: 16,
          maxzoom: 20,
          layout: {
            visibility: "visible",
          },
          paint: {
            // "fill-color": "#649d8d",
            "fill-opacity": 0, // Adjust opacity as needed
            "fill-color": [
              "case",
              ["boolean", ["feature-state", "selected"], false],
              "#ff0000", // Color for selected parcel
              "#649d8d", // Default color
            ],
          },
        });

        map.current.addLayer({
          id: "parcels",
          type: "line",
          source: data.id,
          "source-layer": data.id,
          minzoom: 16,
          maxzoom: 20,
          layout: {
            visibility: "visible",
          },
          paint: {
            "line-color": [
              "case",
              ["boolean", ["feature-state", "selected"], false],
              "#ff0000", // Color for selected parcel
              ["boolean", ["feature-state", "addedToDb"], false],
              "#1C51B8", // Color for addedToDb true
              "#649d8d", // Default color
            ],
            "line-opacity": [
              "case",
              ["boolean", ["feature-state", "selected"], false],
              1, // Color for selected parcel
              1, // Default color
            ],
            "line-width": [
              "case",
              ["boolean", ["feature-state", "addedToDb"], false],
              5, // Line width for parcels where addedToDb is true
              ["boolean", ["feature-state", "selected"], false],
              5, // Line width for selected parcels
              3, // Default line width
            ],
            "line-offset": 2,
          },
        });

        properties?.getProperties?.forEach((property: any) => {
          if (!!property?.parcelnumb) {
            map.current.setFeatureState(
              {
                source: data.id,
                id: property?.parcelnumb,
                sourceLayer: data.id,
              },
              { addedToDb: true }
            );
          }
        });

        // map.current.setFeatureState(
        //   {
        //     source: data.id,
        //     id: "0512221017000",
        //     sourceLayer: data.id,
        //   },
        //   { addedToDb: true }
        // );
      }
    });

    // let lastClickedId: any = null;

    map.current.on("click", "parcels-fill", async function (e: any) {
      if (!!e?.features && e?.features?.length > 0) {
        var feature = e.features[0];
        var parcelnumb = feature?.properties?.parcelnumb; // Replace with actual property name

        if (lastClickedId !== null) {
          // Reset the previous feature state

          map.current.setFeatureState(
            {
              source: e.features[0].source,
              id: lastClickedId,
              sourceLayer: e.features[0].sourceLayer,
            },
            { selected: false }
          );
        }

        const findID = map.current
          .queryRenderedFeatures()
          .filter(
            (fet: any) =>
              fet.properties?.parcelnumb ===
              e.features[0].properties?.parcelnumb
          );

        const clickedId = e.features[0].id;
        lastClickedId = clickedId;

        map.current.setFeatureState(
          {
            source: e?.features[0]?.source,
            id: e?.features[0]?.id,
            sourceLayer: e?.features[0]?.sourceLayer,
          },
          { selected: true }
        );
        // Set the feature state of the clicked feature

        // const accessToken = await getAccessTokenSilently();
        setRegridParcelDetails(feature.properties);

        // setSelectedDetails(feature.properties);

        // Make POST request with Bearer token to your API
        // fetch("https://dev.crm.mercero-api.com/api/1/data/search", {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //     Authorization: `Bearer ${accessToken}`, // Replace with your actual token
        //   },
        //   body: JSON.stringify({
        //     search: { type: "apn", input: { parcelnumb: parcelnumb } },
        //   }),
        // })
        //   .then((response) => {
        //     if (!response.ok) {
        //       throw new Error("Network response was not ok");
        //     }
        //     return response.json();
        //   })
        //   .then((data) => {
        //     // Extract and display the parcel details from the response
        //     setSelectedDetails(data);
        //     // var parcelDetails = displayParcelDetails(data); // Assuming displayParcelDetails handles your response format
        //     // new mapboxgl.Popup()
        //     //   .setLngLat(e.lngLat)
        //     //   .setHTML(parcelDetails)
        //     //   .addTo(map);
        //   })
        //   .catch((error) => {
        //     console.error("Fetch error:", error);
        //     // Handle error, maybe show a message in popup
        //   });
      }
    });

    map.current.on("idle", () => {
      // This event fires when the map has finished loading and rendering all sources and layers,
      // including after any camera transitions.
      // You might consider this a good place to check if everything is truly ready.
      console.log("in here");
    });

    // Change the cursor to a pointer when over the fill layer.
    map.current.on("mouseenter", "parcels-fill", function (e: any) {
      map.current.getCanvas().style.cursor = "pointer";
      // map.current.setFeatureState(
      //   {
      //     source: e.features[0].source,
      //     id: e.features[0]?.id,
      //     sourceLayer: e.features[0].sourceLayer,
      //   },
      //   { selected: true }
      // );
    });

    // Change the cursor to a pointer when over a clickable feature
    map.current.on("mouseenter", "parcels", function () {
      map.current.getCanvas().style.cursor = "pointer";
    });

    // Change it back to a pointer when it leaves.
    map.current.on("mouseleave", "parcels", function (e: any) {
      map.current.getCanvas().style.cursor = "";
      // map.current.setFeatureState(
      //   {
      //     source: e.features[0].source,
      //     id: hoverId,
      //     sourceLayer: e.features[0].sourceLayer,
      //   },
      //   { selected: false }
      // );
    });

    return () => {
      map.current.remove(); // This will clean up the map instance when the component unmounts
    };
  }, [center, url, setRegridParcelDetails]); // Empty dependency array ensures this effect runs once on mount

  // useEffect(() => {
  //   if (selectedDetails?.regrid?.properties?.fields?.parcelnumb) {
  //     map.current.setFeatureState(
  //       {
  //         source: "7f2fc977750e32c6470079e5dac5a8e52a1ccc03",
  //         id: selectedDetails?.regrid?.properties?.fields?.parcelnumb,
  //         sourceLayer: "7f2fc977750e32c6470079e5dac5a8e52a1ccc03",
  //       },
  //       { selected: true }
  //     );
  //   }
  // }, [selectedDetails]);

  useEffect(() => {
    if (
      map.current &&
      center &&
      center[0] !== -98.5795 &&
      center[1] !== 39.8283
    ) {
      map.current.setCenter(center);
      map.current.setZoom(16);
      var marker = new mapboxgl.Marker({ color: "red" }) // Create a new marker
        .setLngLat(center) // Set the position of the marker [lng, lat]
        .addTo(map.current); // Add the marker to the map
    }
  }, [center]);

  useEffect(() => {
    if (areLayersLoaded && map?.current?.isStyleLoaded()) {
      if (filter === "") {
        map.current.setFilter("parcels", null); // Show all parcels
        map.current.setFilter("parcels-fill", null);
      } else {
        // @ts-ignore
        const filterRange = typeToCodeRange[filter];
        if (Array.isArray(filterRange)) {
          // Range filter
          map.current.setFilter("parcels", [
            "all",
            [">=", ["get", "lbcs_activity"], filterRange[0]],
            ["<=", ["get", "lbcs_activity"], filterRange[1]],
          ]);
          map.current.setFilter("parcels-fill", [
            "all",
            [">=", ["get", "lbcs_activity"], filterRange[0]],
            ["<=", ["get", "lbcs_activity"], filterRange[1]],
          ]);
        } else {
          // Single value filter
          map.current.setFilter("parcels", [
            "==",
            ["get", "lbcs_activity"],
            filterRange,
          ]);
          map.current.setFilter("parcels-fill", [
            "==",
            ["get", "lbcs_activity"],
            filterRange,
          ]);
        }
      }
    }
  }, [filter, areLayersLoaded]);

  return (
    <div
      id="newMap"
      key="jkhjk"
      style={{ height: "100%", width: "100%" }}
    ></div>
  ); // Set a height for your map container
}
